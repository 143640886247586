










































































































































































































































import { ref, defineComponent, computed } from "@vue/composition-api";
import { useUserStore } from "@/stores/userStore";
import { useErrorStore } from "@/stores/errorStore";
import { checkPassword } from "@/utility/password_validator";
import OtiumBasePage from "../OtiumBasePage.vue";
import PasswordValidationItem from "./PasswordValidationItem.vue";

export default defineComponent({
  name: "AccountManagement",
  components: {
    OtiumBasePage,
    PasswordValidationItem
  },
  setup(props, { refs }) {
    const userStore = useUserStore();
    const errorStore = useErrorStore();

    // general information && account information
    const profile = ref(Object.assign({}, userStore.userProfile));
    const isOrganizationAdmin = computed(() => userStore.isOrganizationAdmin);

    const requiredRule = ref((v: string) => (v != undefined && v.trim() == "" ? "Required" : true));
    const isGeneralInformationFormValid = ref(false);
    const isAccountInformationFormValid = ref(false);
    const isVerifyPasswordFormValid = ref(false);
    const isChangePasswordFormValid = ref(false);
    const passwordChanged = ref(false);

    function profileUpdated() {
      if (profile.value == null) return;

      (refs.generalInformationForm as any).validate();
      (refs.accountInformationForm as any).validate();
      if (!(isGeneralInformationFormValid.value && isAccountInformationFormValid.value)) return;

      userStore.updateUserProfile(profile.value);
    }

    // password change
    const showChangePassword = ref(false);
    const passwordVisible = ref(false);
    const newPasswordVisible = ref(false);
    const confirmPasswordVisible = ref(false);
    const currentPassword = ref("");
    const newPassword = ref("");
    const confirmNewPassword = ref("");
    const errorMessage = ref("");

    const passwordValidationResult = computed(() => checkPassword(newPassword.value));
    const passwordIsValid = computed(
      () =>
        newPassword.value.trim() != "" &&
        newPassword.value == confirmNewPassword.value &&
        passwordValidationResult.value.isValid()
    );

    async function verifyPassword() {
      passwordChanged.value = false;
      (refs.verifyPasswordForm as HTMLFormElement).validate();
      if (!isVerifyPasswordFormValid.value) return;

      const isValid = await userStore.verifyPassword(currentPassword.value);
      if (isValid) {
        showChangePassword.value = true;
        (refs.verifyPasswordForm as HTMLFormElement).resetValidation();
      } else {
        errorMessage.value = "Invalid password.";
      }
    }

    async function changePassword() {
      (refs.changePasswordForm as HTMLFormElement).validate();
      if (!isChangePasswordFormValid.value) return;

      try {
        await userStore.changePassword(currentPassword.value, newPassword.value);
        passwordChanged.value = true;
        cancelChangePassword();
      } catch {
        errorStore.addError("An error occurred while changing your password.");
      }
    }

    function cancelChangePassword() {
      currentPassword.value = "";
      newPassword.value = "";
      confirmNewPassword.value = "";
      passwordVisible.value = false;
      newPasswordVisible.value = false;
      confirmPasswordVisible.value = false;
      showChangePassword.value = false;
      errorMessage.value = "";
      (refs.changePasswordForm as HTMLFormElement).reset();
    }

    return {
      profile,
      requiredRule,
      isGeneralInformationFormValid,
      isAccountInformationFormValid,
      isChangePasswordFormValid,
      isVerifyPasswordFormValid,
      profileUpdated,
      passwordChanged,

      showChangePassword,
      verifyPassword,
      passwordVisible,
      newPasswordVisible,
      confirmPasswordVisible,
      currentPassword,
      newPassword,
      confirmNewPassword,
      passwordValidationResult,
      passwordIsValid,
      errorMessage,
      changePassword,
      cancelChangePassword,
      isOrganizationAdmin
    };
  }
});
