import { render, staticRenderFns } from "./PasswordValidationItem.vue?vue&type=template&id=45e0236e&scoped=true&"
import script from "./PasswordValidationItem.vue?vue&type=script&lang=ts&"
export * from "./PasswordValidationItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e0236e",
  null
  
)

export default component.exports