






















import { ref, defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  name: "PasswordValidationItem",
  props: {
    valid: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {}
});
